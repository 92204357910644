import { HttpClient } from "../HttpClient";
/**
 * * Post Invite User
 */
export const PostInviteUser = async (userData) => {
	try {
		const { data } = await HttpClient.post("api/users/invite", { ...userData });
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
