<template>
  <LayoutNew>
    <MDBLoading v-model="loadingSpinner" class="analytics-loader" loadingText="" icon="spinner-grow" />
    <h1 class="text-center">Add User</h1>
    <MDBCard id="UserInformation" class="mb-2" tag="form" @submit.prevent="submit">
      <MDBCardHeader class="p-4">
        <h4 class="mb-0">User Information</h4>
      </MDBCardHeader>
      <MDBCardBody class="d-flex flex-column" style="gap: 15px">
        <div>
          Email:
          <MDBInput type="email" placeholder="Email" v-model="form.email" />
        </div>
        <div>
          Partner Percentage:
          <MDBInput type="number" placeholder="Partner Percentage" v-model="partnerPercentage" />
        </div>
        <!-- toggle -->
        <div class="mt-1">
          <MDBSwitch label="Is Distributor" v-model="form.isDistributor" />
        </div>
        <!-- google sheet -->
        <div v-if="form.isDistributor" class="gap-4">
          Google Sheet Info:
          <MDBInput type="text" placeholder="Google Sheet's Link" v-model="form.googleSheetLink" class="mt-3" />
          <MDBInput type="text" placeholder="Google Sheet's SheetName" v-model="form.sheetName" class="mt-3" />
        </div>
        <!-- asset label -->
        <div class="mt-2">
          <MDBBtn type="button" color="secondary" aria-controls="assetLabelModal" @click="assetLabelModal = true"
            size="sm">
            Select Asset Labels
          </MDBBtn>
          <!-- table -->
          <MDBTable hover responsive class="mt-3">
            <tr>
              <th v-for="(item, i) in tableHeaders" :key="i" scope="col" class="p-4">
                {{ item.header }}
              </th>
            </tr>
            <tbody v-if="selectedAssetLabelsArray.length < 1">
              <tr class="text-center align-middle">
                <td colspan="3">
                  <h4>No Selected Asset Label</h4>
                </td>
              </tr>
            </tbody>
            <tbody v-if="selectedAssetLabelsArray.length > 0">
              <tr v-for="(item, i) in selectedAssetLabelsArray" :key="i">
                <td class="align-middle" style="width: 45%;">
                  <select v-model="item.assignedDashboard" class="form-select">
                    <option v-for="option in dashboard" :key="option.value" :value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </td>
                <td class="align-middle">{{ item.name }}</td>
                <td class="d-flex justify-content-end text-right p-4">
                  <MDBBtn color="danger" floating @click.stop.prevent="clickDelete(item)" size="sm">
                    <em class="far fa-trash-alt" />
                  </MDBBtn>
                </td>
              </tr>
            </tbody>
          </MDBTable>
        </div>
      </MDBCardBody>
      <MDBCardFooter class="py-3">
        <MDBBtn type="submit" color="primary" :disabled="isInviteBtnDisabled" size="sm">
          Invite User
        </MDBBtn>
      </MDBCardFooter>
    </MDBCard>
    <!-- modal -->
    <MDBModal id="assetLabelModal" tabindex="-1" labelledby="assetLabelModalLabel" v-model="assetLabelModal" scrollable>
      <MDBModalHeader class="px-4">
        <MDBModalTitle id="assetLabelModalLabel"> Asset Labels </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <div>
          <MDBInput class="mb-4" v-model="dataTableSearch" />
          <MDBDatatable :dataset="assetLabelDataTable" selectable multi :search="dataTableSearch"
            @selected-rows="handleSelectedRow($event)" hover />
        </div>
      </MDBModalBody>
      <MDBModalFooter class="gap-2 py-3">
        <MDBBtn @click="assetLabelModal = false"> Close </MDBBtn>
        <MDBBtn color="primary" @click="assetLabelModal = false">
          Select Asset Labels
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <DeductionsToast />
  </LayoutNew>
</template>

<script setup>
import {
  MDBBtn,
  MDBLoading,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalTitle,
  MDBModalFooter,
  MDBDatatable,
  MDBInput,
  MDBSwitch,
  MDBTable,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import { useRouter } from "vue-router";
import { computed, ref, reactive, watch, onBeforeMount } from "vue";
import axios from "axios";
import { PostInviteUser } from "@/services/Users/PostInviteUser";
import DeductionsToast from "@/components/Deductions/DeductionsToast.vue";
import { useToastStore } from "@/store/toastStore";
import { useTitle } from "@vueuse/core";

useTitle("User Update | CreatorShield");

const router = useRouter();
const assetLabelModal = ref(false);
const toastStore = useToastStore();

const assetLabelDataTable = ref({
  columns: [{ label: "Name", field: "name" }],
  rows: [],
});

const tableLoader = ref(false);
const dataTableSearch = ref("");
const partnerPercentage = ref(0);

onBeforeMount(async () => {
  assetLabelDataTable.value.rows = [];
  tableLoader.value = true;
  await axios.get("api/assetLabels/Dropdown").then(function (data) {
    assetLabelDataTable.value.rows = data.data;
    tableLoader.value = false;
  });
});

const handleSelectedRow = (selectedModalArray) => {
  const newArray = selectedModalArray.map(label => ({
    ...label,
    assignedDashboard: label.assignedDashboard || 1,  // Default to 1 if not set
  }));
  selectedAssetLabelsArray.value = newArray;
};

const clickDelete = (item) => {
  const newArray = selectedAssetLabelsArray.value.filter(
    (element) => element.id != item.id
  );
  selectedAssetLabelsArray.value = newArray;
};

let loadingSpinner = ref(false);

const selectedAssetLabels = ref("");
const selectedAssetLabelsArray = ref([]);
watch([selectedAssetLabels], ([newSelectedAssetLabels]) => {
  selectedAssetLabelsArray.value = newSelectedAssetLabels.split(",");
});

const form = reactive({
  email: "",
  isDistributor: false,
  link: null,
  googleSheetLink: "",
  sheetName: "",
  assignedDashboard: 1,
});

const tableHeaders = computed(() => [
  {
    header: "Dashboard",
  },
  {
    header: "Name",
  },
  {},
]);

const isInviteBtnDisabled = computed(
  () => form.email == "" || partnerPercentage.value == ""
);

const submit = async () => {
  const formData = {
    email: form.email,
    userTypeId: getUserTypeId.value,
    googleSheetLink: form.isDistributor ? form.googleSheetLink : null,
    assignedAssetLabels: selectedAssetLabelsArray.value.map(label => ({
      id: label.id,
      assignedDashboard: label.assignedDashboard,
    })),
    partnerPercentage: partnerPercentage.value,
    sheetName: form.isDistributor ? form.sheetName : null,
  };

  loadingSpinner.value = true;
  const toastSuccess = {
    ...toastStore.toastStatus.success,
    ...{
      message: "Successfully invited!",
    },
  };
  await PostInviteUser(formData)
    .then(() => {
      toastStore.toastObject = {
        ...toastStore.toastObject,
        ...toastSuccess,
      };
    })
    .catch((response) => {
      if (response.status != 200 || response.status != 201) {
        toastStore.toastObject = {
          ...toastStore.toastObject,
          ...toastStore.toastStatus.fail,
        };
      }
    })
    .finally(() => {
      loadingSpinner.value = false;
      router.push({ name: "UserManagement" });
    });
};

const getUserTypeId = computed(() => (form.isDistributor ? 3 : 2));

const dashboard = ref([
  { text: 'CreatorShield & VeryViralVisuals', value: 1 },
  { text: 'CreatorShield', value: 2 },
  { text: 'VeryViralVisuals', value: 3 },
]);
</script>

<style scoped>
.btn-primary {
  background-color: var(--primary);
}

.btn-secondary {
  background-color: var(--accent);
}

.card,
.card-body {
  overflow-y: auto;
}
</style>
